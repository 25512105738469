.label {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 25vw;

}

.wrapper{
    margin-top: 2vw;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.contacts{
    grid-column: 1;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
    padding: 15px;
}
.section{
    margin-bottom: 50px;
}
.map{
    grid-column: 2;
    height: auto;
}
.title{
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;
    text-align: left;
}
.text {
    font-weight: 300;
    font-size: 1.8vw;
    margin-top: 0.5rem;
    line-height: px;
    color: #FFFFFF;
}
@media screen and (max-width: 1024px) {
    
    .wrapper{
        margin-top: 2vw;
        display: grid;
        grid-template-columns: 1fr;
    }
    .map{
        grid-column: 1;
        grid-row: 2;
        height: auto;
    }
    .label{
        font-size: 5vw;
    }
    .text {
        font-size: 2.3vw;
    }
    .title {
        font-size: 3.5vw;
    }
}
@media screen and (max-width: 768px) {
    .section{
        margin-bottom: 20px;
    }
    .label{
        font-size: 5vw;
    }
    .text {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .title {
        font-size: 3.5vw;
        line-height: 30px;
    }
}
@media screen and (max-width: 420px) {
    .label{
        font-size: 6vw;
    }
    .text {
        font-size: 3.5vw;
        line-height: 15px;
        margin-top: 1vh;
    }
    .title {
        font-size: 4.5vw;
        line-height: 20px;
    }
}