.nav {
    padding: 3vw;
    background: linear-gradient(180deg, rgba(23, 9, 56, 1), transparent);
    position: sticky;
    top: 0px;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    font-family: Sansation Light;
    font-weight: 300;
    font-size: 1.8vw;
    color: #adc1f1;
    text-transform: uppercase;
    margin: 0 auto;
    
}

.nav div:hover {
    cursor: pointer;
    color: #FFFFFF;
}

.back {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #7589BD;
}
.back:hover{
    transition: ease-in 0.1s;
    transform: scale(1.2);
}
.back::before{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 130px;
    height: 130px;
    color: #758abd; 
    z-index: 3; 
    
}


.back img {
    width: 100px;
    height: 100px;

}
@media screen and (max-width: 1024px) {
    .nav{
        font-size: 2.8vw;

    }
}

@media screen and (max-width: 768px){
    .nav{
        font-size:3vw;

    }
}


@media screen and (max-width: 420px){
    .nav{
        font-size:12px;
        line-height: 18px;
    }
    .nav div {
    
        margin-left: 0px;
    }
    .back img {
        width: 40px;
        height: 40px;
    }
    .back{
        bottom: 15px;
        right: 10px;
    }
    .back::before{
        bottom: 10px;
        right: 10px;
    }
}
@media screen and (max-width: 320px){
    
}