.label {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 30vw;

}
.slider {
    margin-top: 100px;
}
@media screen and (max-width: 1024px) {
    .label {
        font-size: 5vw;
        margin-bottom: 40px;
    }
    .slider {
        
        width: 700px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 768px) {
    .text {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .title {
        font-size: 3.5vw;
        line-height: 30px;
    }
    .label {
        font-size: 5vw;
    }
    .slider {
        
        width: 650px;
    }
}
@media screen and (max-width: 420px) {
    .label {
        font-size: 6vw;
        margin-bottom: 20px;
        margin-top: 30vw;
       
    }
    .slider {
        width: 350px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 320px) {
    .slider {
        width: 300px;
    }
}