.container_descr {
    margin-top: 100px;
    width: 600px;
    z-index: -1;
  }
  .container_descr .next,
  .container_descr .prev {
    position: absolute;
    border: none;
  }
  .container_descr .next {
    left: 0;
  }
  .container_descr .prev{
right: 0;
  }
  .container_descr img {
    width: 100%;

  }
  .container_descr .slick-thumb {
    position: relative;
}
.container_descr .slick-thumb li {
    width: 100px;
    height: 50px;
    cursor: pointer;
    filter: grayscale();
  }
  .container_descr .slick-thumb li:hover {
   filter: brightness();
   transition: 0.5s;
  }


  
  @media screen and (max-width: 1024px) {
    .container_descr {
      margin-top: 100px;
      width: 700px;
      margin: 0 auto;
    }
    .container_descr .next {
      width: 50px;
    height: 100px;
    }
    .container_descr .prev {
      width: 50px;
    height: 100px;
    }
    .container_descr .arrow1 {
      transform: rotate(-70deg) translate(-8px, 20px);
      width: 33px;
      margin-left: -8px;
      margin-top: 45px;}

      .container_descr .arrow2 {
      transform: rotate(70deg) translate(-8px, -20px);
      width: 33px;
      margin-left: -8px;
      margin-top: 45px;}
    
      .container_descr .arrow3 {
      transform: rotate(-70deg) translate(8px, -20px);
      width: 33px;
      margin-left: 25px;
      margin-top: 45px;}
    
      .container_descr .arrow4 {
      transform: rotate(70deg) translate(8px, 20px);
      width: 33px;
      margin-left: 25px;
      margin-top: 45px;}
  }
  @media screen and (max-width: 768px) {
    .container_descr {
      width: 550px;
    }
  }
  @media screen and (max-width: 420px){
    .container_descr {
      width: 300px;
    }
    .container_descr img {
      width: 100%;
  
    }
    .container_descr .slick-thumb li {
      width: 50px;
    }
    .container_descr .next {
      width: 50px;
    height: 100px;
    }
    .container_descr .prev {
      width: 50px;
    height: 100px;
    }
    .container_descr .arrow1 {
      transform: rotate(-70deg) translate(-5px, 12px);
      width: 20px;
      margin-left: -10px;
      margin-top: 45px;}

      .container_descr .arrow2 {
      transform: rotate(70deg) translate(-5px, -12px);
      width: 20px;
      margin-left: -10px;
      margin-top: 45px;}
    
      .container_descr .arrow3 {
      transform: rotate(-70deg) translate(5px, -12px);
      width: 20px;
      margin-left:40px;
      margin-top: 45px;}
    
      .container_descr .arrow4 {
      transform: rotate(70deg) translate(5px, 12px);
      width: 20px;
      margin-left:40px;
      margin-top: 45px;}
  }
  @media screen and (max-width: 420px){
    .container_descr .next {
      display: block;
      width: 20px;
      height: 50px;
    }
    .container_descr .next:hover{
      background-color: transparent;
    }
    .container_descr .prev {
      display: block;
      width: 50px;
      height: 50px;
    }
    .container_descr .prev:hover{
      background-color: transparent;
    }
  }
  @media screen and (max-width: 320px){
    .container_descr {
      width: 250px;
    }
    .container_descr .slick-thumb li {
      width: 40px;
    }
  }