@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.container{
    
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    flex-direction: column;
    
}
.wrapper{
        margin-right: 5vw;
}
.wrapper:last-child{
    margin-right: 0;
}
.wrapper img {
    align-self: center;
    width: 100%;
    height: auto;
  }
.links{
    margin-top: 2vw;
    text-transform: uppercase;
    color: #B8B3B3;
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-self: center;
    font-size: 1.5vw;
    line-height: 41px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}
.links div:hover {
    cursor: pointer;
    color: grey;
    transform: scale(1.05);
    transition: 0.2s ease-in;
}
.social{
    width: 80%;
    margin-top: 5vw;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    align-items: center;
}
.social img:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.2s ease-in;
}
.social img{
    min-width: 30px;
    min-height: 3px;
}
.rights{
    align-self: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3vw;
    line-height: 24px;
    color: #ADADAD;
}
@media screen and (max-width: 420px) {
    .links{
        width: 90%;
        font-size: 10px;
        line-height: 15px;
    }
    .rights{
        font-size: 7px;
    }
}