body{
    overflow-x: hidden;
}
.main {
    display: grid;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
   
}

.mainPage {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 7fr 5fr;
    column-gap: 80px;
}
.mainPage img {
    z-index: 0;
    width: 100%;
    height: auto;
}
.mainimg {
    z-index: 0;
}

.slogan {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    color: #7589BD;
    text-transform: uppercase;
}
 
.slogan_row1 {
    font-size: 4vw;
    align-self: flex-end;
}
.slogan_row2{
    font-size: 5vw;
    align-self: center;
}

.slogan_row3 {
    font-size: 3vw;
}


@media screen and (max-width: 1024px) {

    .mainPage {
        display: grid;
        margin-top: 104px;
        max-width: 1600px;
        grid-template-columns: auto;
        row-gap: 85px;
    }
    .mainPage img{
        max-width: 900px;
    }
    .slogan {
        line-height: 80px;
        margin-top: 55px;
        text-align: center;
        grid-row: 2;
    }
    .slogan_row1 {
        font-size: 5vw;
    }
    .slogan_row2{
        font-size: 7vw;
    }
    
    .slogan_row3 {
        font-size: 4vw;
    }
    
}
@media screen and (max-width: 768px) {
    .mainPage {
        margin-top: 100px;
    }

    .slogan {
        margin-top: 55px;
        text-align: center;
        line-height: 60px;
        grid-row: 2;
    }
    .slogan_row1 {
        font-size: 5vw;
    }
    .slogan_row2{
        font-size: 7vw;
    }
    
    .slogan_row3 {
        font-size: 4vw;
    }
    
}
@media screen and (max-width: 420px){
    .slogan_row1 {
        font-size: 6vw;
    }
    .slogan_row2{
        font-size: 10vw;
    }
    
    .slogan_row3 {
        font-size: 5vw;
    }
    .mainPage {
        margin-top: 3vh;
        row-gap: 3vh;
    }
    .slogan {
        margin-top: 10vh;
        text-align: center;
        line-height: 30px;
        grid-row: 2;
    }
}