.descr {
    display: grid;
    grid-template-columns: 6fr 5fr;
    column-gap: 100px;
    padding-left: 20px;
    padding-right: 20px;
}
.label {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 227px;

}
.block {
    grid-row: 2;
    background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
    margin-top: 30px;
    padding: 20px;
}
.title {
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;

}
.text {
    margin-top: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 1.8vw;
    line-height: 30px;

    color: #FFFFFF;
    
  }
.slider {
    grid-row: 2;
}
@media screen and (max-width: 1024px) {
    .descr{
        grid-template-columns: none;
        column-gap: none;
    }
    .label{
        font-size: 5vw;
    }
    .text {
        font-size: 2.3vw;
    }
    .title {
        font-size: 3.5vw;
    }
    .slider {
        grid-row: 3;
    }
    .block{
        margin-bottom: 30px;
    } 
}
@media screen and (max-width: 768px){
    .label{
        font-size: 5vw;
    }
    .text {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .title {
        font-size: 3.5vw;
        line-height: 30px;
    }
    .block{
        margin-top: 30px;
    }
} 
@media screen and (max-width: 420px){
    .descr {
        padding: 0;
    }
    .label{
        font-size: 6vw;
        margin-top: 200px;
    }
    .text {
        font-size: 3.5vw;
        line-height: 15px;
        margin-top: 1vh;
    }
    .title {
        font-size: 4.5vw;
        line-height: 20px;
    }
    .block{
        margin-top: 2vw;
        padding: 2.5vw;
    }
}