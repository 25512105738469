.label {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 17vw;

}

.rent {
    display: grid;
    grid-template-columns: 7fr 5fr;
    padding-left: 20px;
    padding-right: 20px;
}
.action {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.call {
    margin-top: 75px;
}

.button_offer {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #F42859;
    box-sizing: border-box;
    border-radius: 68px;
    color: #F42859;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;

}

.button_offer:hover {
    filter: brightness(85%)
}
.button_call:hover {
    filter: brightness(85%)
}
.text {
    font-family: Sansation Light;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 30px;
    color: #7589BD;
    max-width: 560px;
}

.button_call {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #44E296;
    box-sizing: border-box;
    border-radius: 68px;
    color: #44E296;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;
   
    

}

.adv{
    display: grid;
    grid-row: 2;
    grid-column: 1;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 30px;
    margin-top: 40px;
    
}
.item{
display: flex;
background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
padding: 15px;
}
.img {
    margin-left: 40px;
    align-self: center;
}
.descr {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    
}

.descr_title{
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
}
.descr_text {
    font-weight: 300;
    font-size: 1.8vw;
    margin-top: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

@media screen and (max-width: 1024px) {
    .rent{
        grid-template-columns: none;
    }
   .label{
    font-size: 5vw;
   }
   .text{
    font-size: 4vw;
   }
   .action {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
   }
   .descr_title {
    font-size: 3.5vw;
   }
   .descr_text {
    font-size: 2.3vw;
   }
}
@media screen and (max-width: 768px) {
    .img {
        margin-left: 10px;
        align-self: center;
        
    }
    .img img{
        width: 120px;
    }
    .rent{
        grid-template-columns: none;
        padding-left: 0px;
    padding-right: 0px;
    }
   .label{
    font-size: 5vw;
   }
   .text{
       font-size: 37px;
   }
   .action {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
   }
   .descr_title {
    font-size: 3.5vw;
       line-height: 25px;
   }
   .descr_text {
    font-size: 2.6vw;
    margin-top: 10px; 
    line-height: 25px;
   }
}
@media screen and (max-width: 420px) {
    .img {
        margin-left: 10px;
        align-self: center;
        
    }
    .img img{
        width: 20vw;
    }
    .rent{
        grid-template-columns: none;
    }
    .item{
        padding: 2vw;
    }
   .label{
        font-size: 6vw;
       margin-top: 30vw;
   }
   .text{
       font-size: 37px;
   }
   .action {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
       flex-direction: column;
       align-items: center;
       justify-content: space-around;
       text-align: center;
   }
   
   .descr_title {
    font-size: 4.5vw;
    line-height: 20px;
   }
   .descr_text {
    font-size: 3.5vw;
        line-height: 15px;
    margin-top: 10px;
   }
   .button_offer {
    border-radius: 30px;
    font-size: 22px;
    line-height: 20px;
    margin-top: 10px;
}

.text {
    font-size: 22px;
    line-height: 20px;
}

.button_call {
    border-radius: 30px;
    font-size: 22px;
    line-height: 20px;
    margin-top: 10px;
}
.adv{
    grid-template-rows: repeat(3, 1fr);
    row-gap: 15px;
    margin-top: 20px;
    
}

}