.container_part {
    margin: 0 auto;
    width: 90%;
  }
  .container_part .el {
    margin-left: 100px;
    margin-right: 100px;
  }
.container_part img{
    border-radius: 20px;
    width: 70%; 
}
.next, .prev {
    cursor: pointer;
    position: absolute;
    bottom: 45px;
    width: 50px;
    height: 100px;
    top: -50px;
    border-radius: 25px;
    border: 3px #F42859 solid;
  }
  .next {
    left: -35px;
  }
  .next:hover {
    background-color: #F42859;
  }
  .next:hover .arrow1,.arrow2 {
    background-color: rgb(255, 164, 186);
  }
  .next:hover .arrow2 {
    background-color: rgb(255, 164, 186);
  }
  .prev {
    right: -35px;
  }

.prev:hover {
    background-color: #F42859;
  }
.prev:hover .arrow3 {
  background-color: rgb(255, 164, 186);
}
.prev:hover .arrow4 {
  background-color: rgb(255, 164, 186);
}
.arrow1, .arrow2, .arrow3, .arrow4 {
  position: absolute;
  width: 33px;
  margin-top: 37px;  
  height: 3px;
  background-color: #F42859
}
      
.arrow1, .arrow2{
    margin-left: -8px;
    margin-top: 45px;

  }

.arrow3, .arrow4{
  margin-left: 25px;
  margin-top: 45px;
} 

.arrow1 {
  transform: rotate(-70deg) translate(-8px, 20px)}
.arrow2 {
  transform: rotate(70deg) translate(-8px, -20px)}

.arrow3 {
  transform: rotate(-70deg) translate(8px, -20px)}

.arrow4 {
  transform: rotate(70deg) translate(8px, 20px)}

@media screen and (max-width: 1024px) {
  .next, .prev {
    width: 35px;
    height: 70px;
    top: -30px;
  }
  .next {
    left: -5px;
  }
  .prev {
    right: 5px;
  }
  .arrow1, .arrow2, .arrow3, .arrow4 {
    width: 20px;
  }
  .arrow1 {
    transform: rotate(-70deg) translate(-5px, 12px)}
  .arrow2 {
    transform: rotate(70deg) translate(-5px, -12px)}
    .arrow1, .arrow2{
      margin-left: -2px;
      margin-top: 35px;

    }
  .arrow3 {
    transform: rotate(-70deg) translate(5px, -12px)}
  
  .arrow4 {
    transform: rotate(70deg) translate(5px, 12px)}
    .arrow3, .arrow4{
      margin-left: 18px;
      margin-top: 35px;
    } 
}
@media screen and (max-width: 768px) {
  .container_part {
    margin: 0 auto;
    width: 85%;
  }
}
@media screen and (max-width: 420px){
  .next, .prev {
    display: none;
  }
  .container_part {
    width: 80%;
  }
}
@media screen and (max-width: 320px){
  .container_part {
    width: 80%;
  }
}
