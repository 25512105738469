body {
    margin: 0;
    font-family: Sansation Light;
    background: linear-gradient(89.42deg, #170938 69.47%, #660D38 90.54%);

}
.footer{
    background: url(../components/footer/bg.jpg) center;
    height: auto;

}
.shadow{
    width: 100%;
    height: 2vw;
    background: url(../components/footer/shadow.png) top;
}
.container {
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 7px;
    padding-right: 7px;
}
@media screen and (max-width: 1024px) {
    body{
        background:  rgb(23, 9, 56);
    }
    .container {
        max-width: 874px;
    }
}
@media screen and (max-width: 768px){
    .container {
        max-width: 648px;
    }
}
@media screen and (max-width: 420px){
    body{
        background:  #170938;
    }
    .container {
        max-width: 364px;
    }
    
}
@media screen and (max-width: 320px){
    

    
}
