@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body{
    overflow-x: hidden;
}
.mainpage_main__2SR3L {
    display: grid;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
   
}

.mainpage_mainPage__3MPXH {
    display: grid;
    max-width: 1600px;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 80px;
    -webkit-column-gap: 80px;
            column-gap: 80px;
}
.mainpage_mainPage__3MPXH img {
    z-index: 0;
    width: 100%;
    height: auto;
}
.mainpage_mainimg__13nwD {
    z-index: 0;
}

.mainpage_slogan__3NROr {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    color: #7589BD;
    text-transform: uppercase;
}
 
.mainpage_slogan_row1__I2Vsr {
    font-size: 4vw;
    align-self: flex-end;
}
.mainpage_slogan_row2__gxA0_{
    font-size: 5vw;
    align-self: center;
}

.mainpage_slogan_row3__13jM6 {
    font-size: 3vw;
}


@media screen and (max-width: 1024px) {

    .mainpage_mainPage__3MPXH {
        display: grid;
        margin-top: 104px;
        max-width: 1600px;
        grid-template-columns: auto;
        grid-row-gap: 85px;
        row-gap: 85px;
    }
    .mainpage_mainPage__3MPXH img{
        max-width: 900px;
    }
    .mainpage_slogan__3NROr {
        line-height: 80px;
        margin-top: 55px;
        text-align: center;
        grid-row: 2;
    }
    .mainpage_slogan_row1__I2Vsr {
        font-size: 5vw;
    }
    .mainpage_slogan_row2__gxA0_{
        font-size: 7vw;
    }
    
    .mainpage_slogan_row3__13jM6 {
        font-size: 4vw;
    }
    
}
@media screen and (max-width: 768px) {
    .mainpage_mainPage__3MPXH {
        margin-top: 100px;
    }

    .mainpage_slogan__3NROr {
        margin-top: 55px;
        text-align: center;
        line-height: 60px;
        grid-row: 2;
    }
    .mainpage_slogan_row1__I2Vsr {
        font-size: 5vw;
    }
    .mainpage_slogan_row2__gxA0_{
        font-size: 7vw;
    }
    
    .mainpage_slogan_row3__13jM6 {
        font-size: 4vw;
    }
    
}
@media screen and (max-width: 420px){
    .mainpage_slogan_row1__I2Vsr {
        font-size: 6vw;
    }
    .mainpage_slogan_row2__gxA0_{
        font-size: 10vw;
    }
    
    .mainpage_slogan_row3__13jM6 {
        font-size: 5vw;
    }
    .mainpage_mainPage__3MPXH {
        margin-top: 3vh;
        grid-row-gap: 3vh;
        row-gap: 3vh;
    }
    .mainpage_slogan__3NROr {
        margin-top: 10vh;
        text-align: center;
        line-height: 30px;
        grid-row: 2;
    }
}
.description_descr__2kF5V {
    display: grid;
    grid-template-columns: 6fr 5fr;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
    padding-left: 20px;
    padding-right: 20px;
}
.description_label__2OeyV {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 227px;

}
.description_block__1dgfB {
    grid-row: 2;
    background: -webkit-gradient(linear, left top, right top, color-stop(-1.97%, rgba(222, 4, 114, 0.14)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
    margin-top: 30px;
    padding: 20px;
}
.description_title__27Ikh {
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;

}
.description_text__1guaB {
    margin-top: 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 1.8vw;
    line-height: 30px;

    color: #FFFFFF;
    
  }
.description_slider__3-iVp {
    grid-row: 2;
}
@media screen and (max-width: 1024px) {
    .description_descr__2kF5V{
        grid-template-columns: none;
        grid-column-gap: none;
        -webkit-column-gap: none;
                column-gap: none;
    }
    .description_label__2OeyV{
        font-size: 5vw;
    }
    .description_text__1guaB {
        font-size: 2.3vw;
    }
    .description_title__27Ikh {
        font-size: 3.5vw;
    }
    .description_slider__3-iVp {
        grid-row: 3;
    }
    .description_block__1dgfB{
        margin-bottom: 30px;
    } 
}
@media screen and (max-width: 768px){
    .description_label__2OeyV{
        font-size: 5vw;
    }
    .description_text__1guaB {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .description_title__27Ikh {
        font-size: 3.5vw;
        line-height: 30px;
    }
    .description_block__1dgfB{
        margin-top: 30px;
    }
} 
@media screen and (max-width: 420px){
    .description_descr__2kF5V {
        padding: 0;
    }
    .description_label__2OeyV{
        font-size: 6vw;
        margin-top: 200px;
    }
    .description_text__1guaB {
        font-size: 3.5vw;
        line-height: 15px;
        margin-top: 1vh;
    }
    .description_title__27Ikh {
        font-size: 4.5vw;
        line-height: 20px;
    }
    .description_block__1dgfB{
        margin-top: 2vw;
        padding: 2.5vw;
    }
}
.container_descr {
    margin-top: 100px;
    width: 600px;
    z-index: -1;
  }
  .container_descr .next,
  .container_descr .prev {
    position: absolute;
    border: none;
  }
  .container_descr .next {
    left: 0;
  }
  .container_descr .prev{
right: 0;
  }
  .container_descr img {
    width: 100%;

  }
  .container_descr .slick-thumb {
    position: relative;
}
.container_descr .slick-thumb li {
    width: 100px;
    height: 50px;
    cursor: pointer;
    -webkit-filter: grayscale();
            filter: grayscale();
  }
  .container_descr .slick-thumb li:hover {
   -webkit-filter: brightness();
           filter: brightness();
   -webkit-transition: 0.5s;
   transition: 0.5s;
  }


  
  @media screen and (max-width: 1024px) {
    .container_descr {
      margin-top: 100px;
      width: 700px;
      margin: 0 auto;
    }
    .container_descr .next {
      width: 50px;
    height: 100px;
    }
    .container_descr .prev {
      width: 50px;
    height: 100px;
    }
    .container_descr .arrow1 {
      -webkit-transform: rotate(-70deg) translate(-8px, 20px);
              transform: rotate(-70deg) translate(-8px, 20px);
      width: 33px;
      margin-left: -8px;
      margin-top: 45px;}

      .container_descr .arrow2 {
      -webkit-transform: rotate(70deg) translate(-8px, -20px);
              transform: rotate(70deg) translate(-8px, -20px);
      width: 33px;
      margin-left: -8px;
      margin-top: 45px;}
    
      .container_descr .arrow3 {
      -webkit-transform: rotate(-70deg) translate(8px, -20px);
              transform: rotate(-70deg) translate(8px, -20px);
      width: 33px;
      margin-left: 25px;
      margin-top: 45px;}
    
      .container_descr .arrow4 {
      -webkit-transform: rotate(70deg) translate(8px, 20px);
              transform: rotate(70deg) translate(8px, 20px);
      width: 33px;
      margin-left: 25px;
      margin-top: 45px;}
  }
  @media screen and (max-width: 768px) {
    .container_descr {
      width: 550px;
    }
  }
  @media screen and (max-width: 420px){
    .container_descr {
      width: 300px;
    }
    .container_descr img {
      width: 100%;
  
    }
    .container_descr .slick-thumb li {
      width: 50px;
    }
    .container_descr .next {
      width: 50px;
    height: 100px;
    }
    .container_descr .prev {
      width: 50px;
    height: 100px;
    }
    .container_descr .arrow1 {
      -webkit-transform: rotate(-70deg) translate(-5px, 12px);
              transform: rotate(-70deg) translate(-5px, 12px);
      width: 20px;
      margin-left: -10px;
      margin-top: 45px;}

      .container_descr .arrow2 {
      -webkit-transform: rotate(70deg) translate(-5px, -12px);
              transform: rotate(70deg) translate(-5px, -12px);
      width: 20px;
      margin-left: -10px;
      margin-top: 45px;}
    
      .container_descr .arrow3 {
      -webkit-transform: rotate(-70deg) translate(5px, -12px);
              transform: rotate(-70deg) translate(5px, -12px);
      width: 20px;
      margin-left:40px;
      margin-top: 45px;}
    
      .container_descr .arrow4 {
      -webkit-transform: rotate(70deg) translate(5px, 12px);
              transform: rotate(70deg) translate(5px, 12px);
      width: 20px;
      margin-left:40px;
      margin-top: 45px;}
  }
  @media screen and (max-width: 420px){
    .container_descr .next {
      display: block;
      width: 20px;
      height: 50px;
    }
    .container_descr .next:hover{
      background-color: transparent;
    }
    .container_descr .prev {
      display: block;
      width: 50px;
      height: 50px;
    }
    .container_descr .prev:hover{
      background-color: transparent;
    }
  }
  @media screen and (max-width: 320px){
    .container_descr {
      width: 250px;
    }
    .container_descr .slick-thumb li {
      width: 40px;
    }
  }
.rent_label__3b346 {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 17vw;

}

.rent_rent__1Sata {
    display: grid;
    grid-template-columns: 7fr 5fr;
    padding-left: 20px;
    padding-right: 20px;
}
.rent_action__VOuSO {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.rent_call__3Wbsv {
    margin-top: 75px;
}

.rent_button_offer__U4Eyz {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #F42859;
    box-sizing: border-box;
    border-radius: 68px;
    color: #F42859;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;

}

.rent_button_offer__U4Eyz:hover {
    -webkit-filter: brightness(85%);
            filter: brightness(85%)
}
.rent_button_call__3N-Oy:hover {
    -webkit-filter: brightness(85%);
            filter: brightness(85%)
}
.rent_text__tSYg5 {
    font-family: Sansation Light;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 30px;
    color: #7589BD;
    max-width: 560px;
}

.rent_button_call__3N-Oy {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #44E296;
    box-sizing: border-box;
    border-radius: 68px;
    color: #44E296;
    font-weight: 300;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 25px;
   
    

}

.rent_adv__PUUtn{
    display: grid;
    grid-row: 2;
    grid-column: 1;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 30px;
    row-gap: 30px;
    margin-top: 40px;
    
}
.rent_item__-v_7j{
display: flex;
background: -webkit-gradient(linear, left top, right top, color-stop(-1.97%, rgba(222, 4, 114, 0.14)), to(rgba(255, 255, 255, 0)));
background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
padding: 15px;
}
.rent_img__1_XCe {
    margin-left: 40px;
    align-self: center;
}
.rent_descr__CKfJz {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    
}

.rent_descr_title__2ERAx{
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
}
.rent_descr_text__1Svre {
    font-weight: 300;
    font-size: 1.8vw;
    margin-top: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

@media screen and (max-width: 1024px) {
    .rent_rent__1Sata{
        grid-template-columns: none;
    }
   .rent_label__3b346{
    font-size: 5vw;
   }
   .rent_text__tSYg5{
    font-size: 4vw;
   }
   .rent_action__VOuSO {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
   }
   .rent_descr_title__2ERAx {
    font-size: 3.5vw;
   }
   .rent_descr_text__1Svre {
    font-size: 2.3vw;
   }
}
@media screen and (max-width: 768px) {
    .rent_img__1_XCe {
        margin-left: 10px;
        align-self: center;
        
    }
    .rent_img__1_XCe img{
        width: 120px;
    }
    .rent_rent__1Sata{
        grid-template-columns: none;
        padding-left: 0px;
    padding-right: 0px;
    }
   .rent_label__3b346{
    font-size: 5vw;
   }
   .rent_text__tSYg5{
       font-size: 37px;
   }
   .rent_action__VOuSO {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
   }
   .rent_descr_title__2ERAx {
    font-size: 3.5vw;
       line-height: 25px;
   }
   .rent_descr_text__1Svre {
    font-size: 2.6vw;
    margin-top: 10px; 
    line-height: 25px;
   }
}
@media screen and (max-width: 420px) {
    .rent_img__1_XCe {
        margin-left: 10px;
        align-self: center;
        
    }
    .rent_img__1_XCe img{
        width: 20vw;
    }
    .rent_rent__1Sata{
        grid-template-columns: none;
    }
    .rent_item__-v_7j{
        padding: 2vw;
    }
   .rent_label__3b346{
        font-size: 6vw;
       margin-top: 30vw;
   }
   .rent_text__tSYg5{
       font-size: 37px;
   }
   .rent_action__VOuSO {
       margin-top: 40px;
       grid-row: 5;
       grid-column: 1;
       flex-direction: column;
       align-items: center;
       justify-content: space-around;
       text-align: center;
   }
   
   .rent_descr_title__2ERAx {
    font-size: 4.5vw;
    line-height: 20px;
   }
   .rent_descr_text__1Svre {
    font-size: 3.5vw;
        line-height: 15px;
    margin-top: 10px;
   }
   .rent_button_offer__U4Eyz {
    border-radius: 30px;
    font-size: 22px;
    line-height: 20px;
    margin-top: 10px;
}

.rent_text__tSYg5 {
    font-size: 22px;
    line-height: 20px;
}

.rent_button_call__3N-Oy {
    border-radius: 30px;
    font-size: 22px;
    line-height: 20px;
    margin-top: 10px;
}
.rent_adv__PUUtn{
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 15px;
    row-gap: 15px;
    margin-top: 20px;
    
}

}

.offer_overlay__12Ffx {
    position: fixed;
    grid-template-columns: 1fr 1fr 1fr;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.offer_closeFieldRight__2UVU6 {
    display: flex;

 }
.offer_closeFieldLeft__JLXRa{
    display: flex;

}
.offer_box__3usb3 {
    height: 75vh;
    margin-top: 7vh;
    left: 30%;
    display: grid;
    background: linear-gradient(88.43deg, #170938 69.47%, #660D38 90.54%);
    border-radius: 20px;
    padding: 30px;
    z-index: 500;

}

.offer_subBox__3AA0g {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4vw;
    -webkit-column-gap: 4vw;
            column-gap: 4vw;
    margin-top: 20px;
}
.offer_subBoxCol__EmKDf{
    display: flex;
    flex-direction: column;

}
.offer_subI__Xq57U{
    color: white;
    max-width: 200px;
    text-align: bottom;
    width: 10vw;
    grid-row: 2;
    background-color: transparent;
    font-size: 20px;
    position: relative;
    border: 2px solid #F42859;
    border-radius: 30px;
    padding: 10px 10px;
    margin-top: 5px;
}

.offer_close__jIHav {
    margin-top: 3vh;
    line-height: 30px;
    color: #fff;
    font-size: 60px;
    text-align: right;
    cursor: pointer;
}
.offer_text__1pG4P {
    color: rgb(255, 255, 255);
    font-size: 20px;
    margin-top: 10px;
    margin-left: 2.3vw;
}
.offer_text__1pG4P:first-child{
    margin-top: 0;
}
.offer_text__1pG4P p{
    font-size: 20px;
    margin: 0;
    line-height: 25px;
}
.offer_input__ZRw4S {
    max-width: 400px;
    width: 50vw;
    min-width: 100px;
    font-size: 20px;
    position: relative;
    border: 2px solid #F42859;
    border-radius: 30px;
    margin-top: 5px;
    color: white;
    background-color: transparent;
    padding: 7px 7px;

}


.offer_btn__3gJu4 {
    font-family: Sansation Light;
    padding: 15px 15px;
    background-color: transparent;
    border: 2px solid #F42859;
    box-sizing: border-box;
    border-radius: 68px;
    color: #F42859;
    font-weight: 300;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
}
.offer_btn__3gJu4:hover {
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
}

@media screen and (max-width: 1024px){
    .offer_box__3usb3 {
    height: 600px;
    margin-top: 5vh;
    border-radius: 5vw;
    padding: 3vw 5vw 6vw 5vw;
    }
    .offer_subI__Xq57U{
    margin-top: 1vw;
    font-size: 2vw;
    }
    .offer_close__jIHav {
    font-size: 7vw;
    margin-top: 4vw;
    }
    .offer_text__1pG4P {
    font-size: 2.4vw;
    margin-top: 2vw;
    }
    .offer_text__1pG4P p{
        font-size: 2.4vw;
        margin: 0;
        line-height: 25px;
    }
    .offer_input__ZRw4S {
    width: 50vw;
    margin-top: 1vw;
    font-size: 2.4vw;
    }

    .offer_btn__3gJu4 {
    font-size: 2.4vw;
    margin-top: 2vw;
    }


}
@media screen and (max-width: 768px){
    .offer_box__3usb3 {
        height: 60vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 5vw 5vw 7vw 5vw;
    }
    .offer_subI__Xq57U{
        margin-top: 2vw;
    }
    .offer_text__1pG4P {
    font-size: 3vw;
    margin-top: 4vw;
    }
    .offer_text__1pG4P p{
        font-size: 3vw;
        margin: 0;
        line-height: 35px;
    }
    .offer_input__ZRw4S {
    width: 50vw;
    margin-top: 2vw;
    font-size: 3vw;
    }

    .offer_textarea__2NXjc {
    margin-top: 3vw;
    font-size: 3vw;
    }
    .offer_btn__3gJu4 {
    font-size: 3vw;
    margin-top: 3vw;
    }

}
@media screen and (max-width: 420px){
    .offer_box__3usb3 {
        height: 70vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 10vw 10vw;
    }
    .offer_subBox__3AA0g {
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;
        margin-top: 4vw;
    }
    .offer_subI__Xq57U{
    
    font-size: 5vw;
    padding: 5px 5px;
    }
    .offer_close__jIHav {
        font-size: 15vw;
        margin-top: 20px;
    }
    .offer_text__1pG4P {
    font-size: 4.5vw;
    margin-top: 4vw;
    }
    .offer_text__1pG4P p{
        font-size: 4vw;
        margin: 0;
        line-height: 20px;
    }
    .offer_input__ZRw4S {
    width: 50vw;
    margin-top: 1vw;
    font-size: 4vw;
    padding: 5px 5px;
    }

    .offer_textarea__2NXjc {
    margin-top: 3vw;
    font-size: 5vw;
    }
    .offer_btn__3gJu4 {
    margin-top: 10px;
    font-size: 5vw;
    padding: 10px 6px;
    }

}
.callback_overlay__2peJQ {
    position: fixed;
    grid-template-columns: 1fr 1fr 1fr;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.callback_closeFieldRight__Fvhmt {
    display: flex;

 }
.callback_closeFieldLeft__16E8I{
    display: flex;

}
.callback_box__36AWJ {
    height: 70vh;
    margin-top: 10vh;
    grid-column-gap: 2;
    -webkit-column-gap: 2;
            column-gap: 2;
    left: 30%;
    display: grid;
    background: linear-gradient(88.43deg, #170938 69.47%, #660D38 90.54%);
    border-radius: 20px;
    padding: 2vw 3vw 3vw 3vw;
    z-index: 500;

}
.callback_close__SK0Lo {
    margin-top: 40px;
    line-height: 30px;
    color: #fff;
    font-size: 60px;
    text-align: right;
    cursor: pointer;
}
.callback_text__3-q7L {
    color: rgb(255, 255, 255);
    font-size: 25px;
    margin-top: 20px;
    line-height: 20px;
    margin-left: 2.3vw;
}
.callback_text__3-q7L:first-child{
    margin-top: 0;
}
.callback_text__3-q7L p{
    font-size: 20px;
    margin: 0;
    line-height: 35px;
}
.callback_input__2hzk7 {
    max-width: 400px;
    width: 50vw;
    min-width: 100px;
    font-size: 25px;
    position: relative;
    border: 2px solid #44E296;
    border-radius: 30px;
    margin-top: 10px;
    color: white;
    background-color: transparent;
    padding: 10px 10px;

}


.callback_btn__1ZHqm {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #44E296;
    box-sizing: border-box;
    border-radius: 68px;
    color: #44E296;
    font-weight: 300;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
}
.callback_btn__1ZHqm:hover {
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
}

@media screen and (max-width: 1024px){
    .callback_box__36AWJ {
    height: 55vw;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 4vw 5vw 5vw 5vw;
    }
    .callback_close__SK0Lo {
    font-size: 7vw;
    margin-top: 5vw;
    }
    .callback_text__3-q7L {
    font-size: 3vw;
    margin-top: 3vw;
    }
    .callback_text__3-q7L p{
        font-size: 2vw;
        margin: 0;
        line-height: 35px;
    }
    .callback_input__2hzk7 {
        max-height: 40px;
    width: 50vw;
    margin-top: 1vw;
    font-size: 3vw;
    }

    .callback_btn__1ZHqm {
    font-size: 3vw;
    margin-top: 2vw;
    }


}
@media screen and (max-width: 768px){
    .callback_box__36AWJ {
        height: 60vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 5vw 5vw;
    }
    .callback_close__SK0Lo {
        font-size: 10vw;
        margin-top: 10vw;
        }
    .callback_text__3-q7L {
    font-size: 3vw;
    margin-top: 5vw;
    }
    .callback_text__3-q7L p{
        font-size: 3vw;
        margin: 0;
        line-height: 35px;
    }
    .callback_input__2hzk7 {
    width: 50vw;
    margin-top: 1vw;
    font-size: 3vw;
    }

    .callback_textarea__29w8k {
    margin-top: 3vw;
    font-size: 3vw;
    }
    .callback_btn__1ZHqm {
    font-size: 3vw;
    margin-top: 5vw;
    }

}
@media screen and (max-width: 420px){
    .callback_box__36AWJ {
        height: 70vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 10vw 10vw;
    }
    .callback_close__SK0Lo {
        font-size: 20vw;
        margin-top: 20px;
    }
    .callback_text__3-q7L {
    font-size: 5vw;
    margin-top: 4vw;
    }
    .callback_text__3-q7L p{
        font-size: 4vw;
        margin: 0;
    }
    .callback_input__2hzk7 {
    width: 50vw;
    margin-top: 1vw;
    font-size: 4vw;
    padding: 5px 5px;
    }

    .callback_btn__1ZHqm {
    margin-top: 10px;
    font-size: 5vw;
    padding: 10px 6px;
    }

}
.contacts_label__erHja {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 25vw;

}

.contacts_wrapper__clTGE{
    margin-top: 2vw;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.contacts_contacts__1Z9zp{
    grid-column: 1;
    display: flex;
    flex-direction: column;
    background: -webkit-gradient(linear, left top, right top, color-stop(-1.97%, rgba(222, 4, 114, 0.14)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(90deg, rgba(222, 4, 114, 0.14) -1.97%, rgba(255, 255, 255, 0) 100%);
    padding: 15px;
}
.contacts_section__qrTW5{
    margin-bottom: 50px;
}
.contacts_map__19X_T{
    grid-column: 2;
    height: auto;
}
.contacts_title__1_oVD{
    font-style: normal;
    font-weight: 600;
    font-size: 2.3vw;
    line-height: 40px;
    color: #FFFFFF;
    text-align: left;
}
.contacts_text__1Gba4 {
    font-weight: 300;
    font-size: 1.8vw;
    margin-top: 0.5rem;
    line-height: px;
    color: #FFFFFF;
}
@media screen and (max-width: 1024px) {
    
    .contacts_wrapper__clTGE{
        margin-top: 2vw;
        display: grid;
        grid-template-columns: 1fr;
    }
    .contacts_map__19X_T{
        grid-column: 1;
        grid-row: 2;
        height: auto;
    }
    .contacts_label__erHja{
        font-size: 5vw;
    }
    .contacts_text__1Gba4 {
        font-size: 2.3vw;
    }
    .contacts_title__1_oVD {
        font-size: 3.5vw;
    }
}
@media screen and (max-width: 768px) {
    .contacts_section__qrTW5{
        margin-bottom: 20px;
    }
    .contacts_label__erHja{
        font-size: 5vw;
    }
    .contacts_text__1Gba4 {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .contacts_title__1_oVD {
        font-size: 3.5vw;
        line-height: 30px;
    }
}
@media screen and (max-width: 420px) {
    .contacts_label__erHja{
        font-size: 6vw;
    }
    .contacts_text__1Gba4 {
        font-size: 3.5vw;
        line-height: 15px;
        margin-top: 1vh;
    }
    .contacts_title__1_oVD {
        font-size: 4.5vw;
        line-height: 20px;
    }
}
.footer_container__1VBf5{
    
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    flex-direction: column;
    
}
.footer_wrapper__2GSsU{
        margin-right: 5vw;
}
.footer_wrapper__2GSsU:last-child{
    margin-right: 0;
}
.footer_wrapper__2GSsU img {
    align-self: center;
    width: 100%;
    height: auto;
  }
.footer_links__2sTBN{
    margin-top: 2vw;
    text-transform: uppercase;
    color: #B8B3B3;
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-self: center;
    font-size: 1.5vw;
    line-height: 41px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}
.footer_links__2sTBN div:hover {
    cursor: pointer;
    color: grey;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}
.footer_social__3Vujn{
    width: 80%;
    margin-top: 5vw;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    align-items: center;
}
.footer_social__3Vujn img:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}
.footer_social__3Vujn img{
    min-width: 30px;
    min-height: 3px;
}
.footer_rights__3M8JZ{
    align-self: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3vw;
    line-height: 24px;
    color: #ADADAD;
}
@media screen and (max-width: 420px) {
    .footer_links__2sTBN{
        width: 90%;
        font-size: 10px;
        line-height: 15px;
    }
    .footer_rights__3M8JZ{
        font-size: 7px;
    }
}
body {
    margin: 0;
    font-family: Sansation Light;
    background: linear-gradient(89.42deg, #170938 69.47%, #660D38 90.54%);

}
.main_footer__1l5jk{
    background: url(/static/media/bg.cc495c9f.jpg) center;
    height: auto;

}
.main_shadow__1x61L{
    width: 100%;
    height: 2vw;
    background: url(/static/media/shadow.ea778cca.png) top;
}
.main_container__1B4CG {
    max-width: 1600px;
    margin: 0 auto;
    padding-left: 7px;
    padding-right: 7px;
}
@media screen and (max-width: 1024px) {
    body{
        background:  rgb(23, 9, 56);
    }
    .main_container__1B4CG {
        max-width: 874px;
    }
}
@media screen and (max-width: 768px){
    .main_container__1B4CG {
        max-width: 648px;
    }
}
@media screen and (max-width: 420px){
    body{
        background:  #170938;
    }
    .main_container__1B4CG {
        max-width: 364px;
    }
    
}
@media screen and (max-width: 320px){
    

    
}

@font-face{
    font-family: "Sansation Light"; src: url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.eot"); src: url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.woff") format("woff"), url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/99f7456dfd298c4545c7687ce8d5539d.svg#Sansation Light") format("svg");

}

.nav_nav__28-85 {
    padding: 3vw;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(23, 9, 56, 1)), to(transparent));
    background: linear-gradient(180deg, rgba(23, 9, 56, 1), transparent);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    font-family: Sansation Light;
    font-weight: 300;
    font-size: 1.8vw;
    color: #adc1f1;
    text-transform: uppercase;
    margin: 0 auto;
    
}

.nav_nav__28-85 div:hover {
    cursor: pointer;
    color: #FFFFFF;
}

.nav_back__2BSIg {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #7589BD;
}
.nav_back__2BSIg:hover{
    -webkit-transition: ease-in 0.1s;
    transition: ease-in 0.1s;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.nav_back__2BSIg::before{
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 130px;
    height: 130px;
    color: #758abd; 
    z-index: 3; 
    
}


.nav_back__2BSIg img {
    width: 100px;
    height: 100px;

}
@media screen and (max-width: 1024px) {
    .nav_nav__28-85{
        font-size: 2.8vw;

    }
}

@media screen and (max-width: 768px){
    .nav_nav__28-85{
        font-size:3vw;

    }
}


@media screen and (max-width: 420px){
    .nav_nav__28-85{
        font-size:12px;
        line-height: 18px;
    }
    .nav_nav__28-85 div {
    
        margin-left: 0px;
    }
    .nav_back__2BSIg img {
        width: 40px;
        height: 40px;
    }
    .nav_back__2BSIg{
        bottom: 15px;
        right: 10px;
    }
    .nav_back__2BSIg::before{
        bottom: 10px;
        right: 10px;
    }
}
@media screen and (max-width: 320px){
    
}
.partners_label__24w0G {
    grid-row: 1;
    color: #7589BD;
    font-size: 4vw;
    text-transform: uppercase;
    margin-top: 30vw;

}
.partners_slider__4PXGY {
    margin-top: 100px;
}
@media screen and (max-width: 1024px) {
    .partners_label__24w0G {
        font-size: 5vw;
        margin-bottom: 40px;
    }
    .partners_slider__4PXGY {
        
        width: 700px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 768px) {
    .partners_text__1VS4_ {
        font-size: 2.6vw;
        line-height: 25px;
        margin-top: 15px;
    }
    .partners_title__14vA9 {
        font-size: 3.5vw;
        line-height: 30px;
    }
    .partners_label__24w0G {
        font-size: 5vw;
    }
    .partners_slider__4PXGY {
        
        width: 650px;
    }
}
@media screen and (max-width: 420px) {
    .partners_label__24w0G {
        font-size: 6vw;
        margin-bottom: 20px;
        margin-top: 30vw;
       
    }
    .partners_slider__4PXGY {
        width: 350px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 320px) {
    .partners_slider__4PXGY {
        width: 300px;
    }
}
.container_part {
    margin: 0 auto;
    width: 90%;
  }
  .container_part .el {
    margin-left: 100px;
    margin-right: 100px;
  }
.container_part img{
    border-radius: 20px;
    width: 70%; 
}
.next, .prev {
    cursor: pointer;
    position: absolute;
    bottom: 45px;
    width: 50px;
    height: 100px;
    top: -50px;
    border-radius: 25px;
    border: 3px #F42859 solid;
  }
  .next {
    left: -35px;
  }
  .next:hover {
    background-color: #F42859;
  }
  .next:hover .arrow1,.arrow2 {
    background-color: rgb(255, 164, 186);
  }
  .next:hover .arrow2 {
    background-color: rgb(255, 164, 186);
  }
  .prev {
    right: -35px;
  }

.prev:hover {
    background-color: #F42859;
  }
.prev:hover .arrow3 {
  background-color: rgb(255, 164, 186);
}
.prev:hover .arrow4 {
  background-color: rgb(255, 164, 186);
}
.arrow1, .arrow2, .arrow3, .arrow4 {
  position: absolute;
  width: 33px;
  margin-top: 37px;  
  height: 3px;
  background-color: #F42859
}
      
.arrow1, .arrow2{
    margin-left: -8px;
    margin-top: 45px;

  }

.arrow3, .arrow4{
  margin-left: 25px;
  margin-top: 45px;
} 

.arrow1 {
  -webkit-transform: rotate(-70deg) translate(-8px, 20px);
          transform: rotate(-70deg) translate(-8px, 20px)}
.arrow2 {
  -webkit-transform: rotate(70deg) translate(-8px, -20px);
          transform: rotate(70deg) translate(-8px, -20px)}

.arrow3 {
  -webkit-transform: rotate(-70deg) translate(8px, -20px);
          transform: rotate(-70deg) translate(8px, -20px)}

.arrow4 {
  -webkit-transform: rotate(70deg) translate(8px, 20px);
          transform: rotate(70deg) translate(8px, 20px)}

@media screen and (max-width: 1024px) {
  .next, .prev {
    width: 35px;
    height: 70px;
    top: -30px;
  }
  .next {
    left: -5px;
  }
  .prev {
    right: 5px;
  }
  .arrow1, .arrow2, .arrow3, .arrow4 {
    width: 20px;
  }
  .arrow1 {
    -webkit-transform: rotate(-70deg) translate(-5px, 12px);
            transform: rotate(-70deg) translate(-5px, 12px)}
  .arrow2 {
    -webkit-transform: rotate(70deg) translate(-5px, -12px);
            transform: rotate(70deg) translate(-5px, -12px)}
    .arrow1, .arrow2{
      margin-left: -2px;
      margin-top: 35px;

    }
  .arrow3 {
    -webkit-transform: rotate(-70deg) translate(5px, -12px);
            transform: rotate(-70deg) translate(5px, -12px)}
  
  .arrow4 {
    -webkit-transform: rotate(70deg) translate(5px, 12px);
            transform: rotate(70deg) translate(5px, 12px)}
    .arrow3, .arrow4{
      margin-left: 18px;
      margin-top: 35px;
    } 
}
@media screen and (max-width: 768px) {
  .container_part {
    margin: 0 auto;
    width: 85%;
  }
}
@media screen and (max-width: 420px){
  .next, .prev {
    display: none;
  }
  .container_part {
    width: 80%;
  }
}
@media screen and (max-width: 320px){
  .container_part {
    width: 80%;
  }
}

.mail_overlay__2_9tn {
    position: fixed;
    grid-template-columns: 1fr 1fr 1fr;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.mail_closeFieldRight__3oqTm {
    display: flex;

 }
.mail_closeFieldLeft__1LJrR{
    display: flex;

}
.mail_box__3VjeV {
    height: 70vh;
    margin-top: 10vh;
    grid-column-gap: 2;
    -webkit-column-gap: 2;
            column-gap: 2;
    left: 30%;
    display: grid;
    background: linear-gradient(88.43deg, #170938 69.47%, #660D38 90.54%);
    border-radius: 20px;
    padding: 3vw 5vw;
    z-index: 500;

}
.mail_close__c2IhK {
    margin-top: 40px;
    line-height: 30px;
    color: #fff;
    font-size: 60px;
    text-align: right;
    cursor: pointer;
}
.mail_text__2reaD {
    color: rgb(255, 255, 255);
    font-size: 25px;
    margin-top: 30px;
    line-height: 20px;
    margin-left: 2.3vw;
}
.mail_text__2reaD:first-child{
    margin-top: 0;
}
.mail_input__1y-14 {
    max-width: 400px;
    width: 50vw;
    min-width: 100px;
    font-size: 25px;
    position: relative;
    border: 2px solid #287af4;
    border-radius: 30px;
    margin-top: 10px;
    color: white;
    background-color: transparent;
    padding: 10px 10px;

}
.mail_textarea__3wplZ {
    border: 2px solid #287af4;
    border-radius: 30px;
    padding: 10px 10px;
    margin-top: 10px;
    font-size: 25px;
    color: white;
    resize: none;
    background-color: transparent;
}
.mail_textarea__3wplZ:focus{
    outline: none;
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
    box-shadow: 0px 0px 3px  #a3c8ff;
}

.mail_btn__3PF-U {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #287af4;
    box-sizing: border-box;
    border-radius: 68px;
    color: #287af4;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
}
.mail_btn__3PF-U:hover {
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
}

@media screen and (max-width: 1024px){
    .mail_box__3VjeV {
        height: 55vw;
    margin-top: 7vw;
    border-radius: 5vw;
    padding: 5vw 5vw;
    }
    .mail_close__c2IhK {
    font-size: 6vw;
    margin-top: 4vw;
    }
    .mail_text__2reaD {
    font-size: 2.4vw;
    margin-top: 3vw;
    }
    .mail_input__1y-14 {
    width: 50vw;
    margin-top: 2vw;
    font-size: 2.4vw;
    }

    .mail_textarea__3wplZ {
    margin-top: 3vw;
    font-size: 2.4vw;
    }
    .mail_btn__3PF-U {
    font-size: 2.4vw;
    margin-top: 3vw;
    }


}
@media screen and (max-width: 768px){
    .mail_box__3VjeV {
        height: 60vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 5vw 5vw;
    }
    .mail_close__c2IhK {
        font-size: 10vw;
        margin-top: 10vw;
        }
    .mail_text__2reaD {
    font-size: 3vw;
    margin-top: 3vw;
    }
    .mail_input__1y-14 {
    width: 50vw;
    margin-top: 1vw;
    font-size: 3vw;
    }

    .mail_textarea__3wplZ {
    margin-top: 1vw;
    font-size: 3vw;
    }
    .mail_btn__3PF-U {
    font-size: 3vw;
    margin-top: 7vw;
    }

}
@media screen and (max-width: 420px){
    .mail_box__3VjeV {
        height: 70vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 10vw 10vw;
    }
    .mail_close__c2IhK {
        font-size: 20vw;
        margin-top: 20px;
    }
    .mail_text__2reaD {
    font-size: 6vw;
    margin-top: 5vw;
    }
    .mail_input__1y-14 {
    width: 50vw;
    margin-top: 3vw;
    font-size: 6vw;
    }

    .mail_textarea__3wplZ {
    margin-top: 3vw;
    font-size: 5vw;
    }
    .mail_btn__3PF-U {
    font-size: 5vw;
    padding: 10px 6px;
    }

}
