.overlay {
    position: fixed;
    grid-template-columns: 1fr 1fr 1fr;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.closeFieldRight {
    display: flex;

 }
.closeFieldLeft{
    display: flex;

}
.box {
    height: 70vh;
    margin-top: 10vh;
    column-gap: 2;
    left: 30%;
    display: grid;
    background: linear-gradient(88.43deg, #170938 69.47%, #660D38 90.54%);
    border-radius: 20px;
    padding: 3vw 5vw;
    z-index: 500;

}
.close {
    margin-top: 40px;
    line-height: 30px;
    color: #fff;
    font-size: 60px;
    text-align: right;
    cursor: pointer;
}
.text {
    color: rgb(255, 255, 255);
    font-size: 25px;
    margin-top: 30px;
    line-height: 20px;
    margin-left: 2.3vw;
}
.text:first-child{
    margin-top: 0;
}
.input {
    max-width: 400px;
    width: 50vw;
    min-width: 100px;
    font-size: 25px;
    position: relative;
    border: 2px solid #287af4;
    border-radius: 30px;
    margin-top: 10px;
    color: white;
    background-color: transparent;
    padding: 10px 10px;

}
.textarea {
    border: 2px solid #287af4;
    border-radius: 30px;
    padding: 10px 10px;
    margin-top: 10px;
    font-size: 25px;
    color: white;
    resize: none;
    background-color: transparent;
}
.textarea:focus{
    outline: none;
    filter: brightness(150%);
    box-shadow: 0px 0px 3px  #a3c8ff;
}

.btn {
    font-family: Sansation Light;
    padding: 15px 50px;
    background-color: transparent;
    border: 2px solid #287af4;
    box-sizing: border-box;
    border-radius: 68px;
    color: #287af4;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
}
.btn:hover {
    filter: brightness(150%);
}

@media screen and (max-width: 1024px){
    .box {
        height: 55vw;
    margin-top: 7vw;
    border-radius: 5vw;
    padding: 5vw 5vw;
    }
    .close {
    font-size: 6vw;
    margin-top: 4vw;
    }
    .text {
    font-size: 2.4vw;
    margin-top: 3vw;
    }
    .input {
    width: 50vw;
    margin-top: 2vw;
    font-size: 2.4vw;
    }

    .textarea {
    margin-top: 3vw;
    font-size: 2.4vw;
    }
    .btn {
    font-size: 2.4vw;
    margin-top: 3vw;
    }


}
@media screen and (max-width: 768px){
    .box {
        height: 60vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 5vw 5vw;
    }
    .close {
        font-size: 10vw;
        margin-top: 10vw;
        }
    .text {
    font-size: 3vw;
    margin-top: 3vw;
    }
    .input {
    width: 50vw;
    margin-top: 1vw;
    font-size: 3vw;
    }

    .textarea {
    margin-top: 1vw;
    font-size: 3vw;
    }
    .btn {
    font-size: 3vw;
    margin-top: 7vw;
    }

}
@media screen and (max-width: 420px){
    .box {
        height: 70vh;
    margin-top: 10vh;
    border-radius: 5vw;
    padding: 10vw 10vw;
    }
    .close {
        font-size: 20vw;
        margin-top: 20px;
    }
    .text {
    font-size: 6vw;
    margin-top: 5vw;
    }
    .input {
    width: 50vw;
    margin-top: 3vw;
    font-size: 6vw;
    }

    .textarea {
    margin-top: 3vw;
    font-size: 5vw;
    }
    .btn {
    font-size: 5vw;
    padding: 10px 6px;
    }

}